<template>
  <b-card class="overflow-scroll">
    <h4 class="m-0">
      <feather-icon
        icon="AlertTriangleIcon"
        size="21"
        class="text-warning"
        style="margin-bottom: 3px;"
      />
      Low {{ location.location_name }} Inventory
    </h4>

    <b-table
      :items="inventories"
      :fields="tableColumns"
      :busy="isLoading"
      class="my-1"
      show-empty
      striped
      bordered
      :empty-text="'No items currently with an inventory less than the specified threshold.'"
    >
      <template v-slot:table-busy>
        <div class="d-flex justify-content-center my-4">
          <b-spinner
            type="grow"
            variant="primary"
          />
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import {
  BCard,
  BTable,
  BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import locationStoreService from '@/services/locationStoreService'

export default {
  name: 'LowInventoryCard',
  components: {
    BCard,
    BTable,
    BSpinner,
  },
  props: {
    location: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const LOCATIONS_MODULE_KEY = 'app-location'
    if (!store.hasModule(LOCATIONS_MODULE_KEY)) store.registerModule(LOCATIONS_MODULE_KEY, locationStoreService)

    onUnmounted(() => {
      if (store.hasModule(LOCATIONS_MODULE_KEY)) store.unregisterModule(LOCATIONS_MODULE_KEY)
    })

    const isLoading = ref(true)
    const inventories = ref([])

    const fetchInventory = () => {
      store.dispatch(`${LOCATIONS_MODULE_KEY}/itemInventoryReport`, {
        locationId: props.location.location_id,
        enforceThreshold: true,
      })
        .then(response => {
          inventories.value = response.data
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    fetchInventory()

    return {
      isLoading,
      inventories,
    }
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'item_name',
          label: 'Name',
        },
        {
          key: 'item_display_name',
          label: 'Display Name',
        },
        {
          key: 'item_quantity',
          label: 'Quantity',
        },
      ]
    },
  },
}
</script>

<style scoped>

</style>
