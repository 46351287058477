<template>
  <div>
    <b-row>
      <b-col
        v-for="location in locations"
        :key="'location-' + location.location_id + '-card-'"
        md="6"
      >
        <low-inventory-card
          style="height: 450px;"
          :location="location"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import {
  ref,
  onUnmounted
} from '@vue/composition-api'
import LowInventoryCard from '@/views/locations/LowInventoryCard.vue'
import store from '@/store'
import locationStoreService from '@/services/locationStoreService'

export default {
  name: 'AdminDashboard',
  components: {
    LowInventoryCard,
    BRow,
    BCol,
  },
  setup() {
    const LOCATIONS_MODULE_KEY = 'app-location'
    if (!store.hasModule(LOCATIONS_MODULE_KEY)) store.registerModule(LOCATIONS_MODULE_KEY, locationStoreService)

    onUnmounted(() => {
      if (store.hasModule(LOCATIONS_MODULE_KEY)) store.unregisterModule(LOCATIONS_MODULE_KEY)
    })

    const locations = ref(null)

    const fetchLocations = () => {
      store.dispatch(`${LOCATIONS_MODULE_KEY}/fetch`)
        .then(response => {
          locations.value = response.data
        })
    }

    fetchLocations()

    return {
      locations,
    }
  },
}
</script>

<style scoped>

</style>
